/* ========================
Custom Scss
======================== */

// Your variable overrides
$blue: #03a9f4 !default;
$indigo: #303f9f !default;
$purple: #5e35b1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #ec6426 !default;
$yellow: #f5a623 !default;
$green: #4caf50 !default;
$teal: #00897b !default;
$cyan: #00acc1 !default;
$white: #fafafa !default;

$gray-100: #d0d0d0;
$gray-200: #949598;
$gray-800: #8a8a8a;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-200,
  gray-dark: $gray-800
) !default;

$theme-colors: (
  primary: $yellow,
  secondary: $gray-200,
  success: $green,
  info: $cyan,
  warning: $orange,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
) !default;
