/* ========================
Blog
======================== */

.blog__wrapper {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.blog__wrapper h2 {
  text-align: center;
  z-index: 2;
  position: relative;
  color: #fff;
  padding: 3em;
  padding-top: 6em;
  font-size: 2em;
  font-family: 'Lato', sans-serif;
}

.blog__wrapper img {
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // height: 500px;
  z-index: 1;
  opacity: 0.6;
}

div.container {
  background: #fafafa;
}

article {
  background: #fafafa;
  padding: 15px;
  max-width: 900px;
  display: block;
  margin: 2em auto;

  p {
    font-size: 1em;
    margin-top: 2em;
    margin-bottom: 2em;
    line-height: 1.5;
  }

  ul {
    margin: 1.2em;
    list-style: initial;

    li {
      font-family: 'Lato', sans-serif;
    }
  }
}

hr.spacer {
  max-width: 900px;
  display: block;
  margin: 2em auto;
  border-top: 6px solid #ebebeb;
}

// More Blog Content
.grid__blog {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.25em;
  max-width: 900px;
  justify-items: center;
  margin: 2em auto 5em;
}

@media screen and (max-width: 1200px) {
  .grid__blog {
    padding: 2em;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 650px) {
  .grid__blog {
    padding: 1em;
  }

  article {
    padding: 1em;
  }

  .card__blog img {
    width: 90%;
    display: block;
    margin: 0 auto;
  }
}

.card__blog {
  margin-top: 0.75em;
  // text-align: center;

  img {
    width: 100%;
    max-width: 400px;
    display: block;
    margin: 0 auto;
  }

  h4 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.25em;
    line-height: 1.4;
    margin: 1em 0;
    max-width: 400px;
  }
  span {
    display: block;
    font-size: 0.875em;
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 1.5;
  }
  // button {
  //   display: block;
  //   margin: 0 auto;
  // }
}

.share__box {
  padding: 1em 0;
  background: #eee;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 6px;
  margin: 1em 0;
  display: none;
  transition: all 0.3s ease;
}

.active {
  display: flex;
}
