/* ========================
Home
======================== */

$open-sans: 'Open Sans';
$lato: 'Lato';

// Hero Wrapper --> Home
section#top {
  height: auto;
  color: #fafafa;
}

section#top,
section#top #top-banner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-color: none;
  z-index: 1;
}

section#top #top-banner {
  height: 100vh;
}

@media only screen and (max-width: 736px) {
  section#top #top-banner #top-banner-center-logo {
    width: 90px;
    height: auto;
  }
}

section#top #top-banner #top-banner-background-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  z-index: 5;
}

section#top #top-banner #top-banner-background-1,
section#top #top-banner #top-banner-background-2,
section#top #top-banner #top-banner-background-3,
section#top #top-banner #top-banner-background-4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: none;
  z-index: 1;
  overflow: hidden;
}

section#top #top-banner #top-banner-background-1 div,
section#top #top-banner #top-banner-background-2 div,
section#top #top-banner #top-banner-background-3 div,
section#top #top-banner #top-banner-background-4 div {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw + 100px);
  height: 100%;
  background-color: none;
  z-index: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: all 9s linear;
  overflow: hidden;
}

section#top #top-banner #top-banner-background-1 div.active,
section#top #top-banner #top-banner-background-2 div.active,
section#top #top-banner #top-banner-background-3 div.active,
section#top #top-banner #top-banner-background-4 div.active {
  -webkit-transform: translateX(-100px);
  transform: translateX(-100px);
}

section#top #top-banner #top-banner-background-1 div {
  background: url('http://www.hotelnclapaz.com/restapi/public/archivos/temp/home-mitru-01.jpg');
  -ms-background-size: cover;
  background-size: cover;
  background-position: 50% 85%;
  background-repeat: no-repeat;
}

section#top #top-banner #top-banner-background-2 div {
  background: url('http://www.hotelnclapaz.com/restapi/public/archivos/temp/home-mitru-03.jpg');
  -ms-background-size: cover;
  background-size: cover;
  background-position: 50% 85%;
  background-repeat: no-repeat;
}

section#top #top-banner #top-banner-background-3 div {
  background: url('http://www.hotelnclapaz.com/restapi/public/archivos/temp/home-mitru-04.jpg');
  -ms-background-size: cover;
  background-size: cover;
  background-position: 50% 85%;
  background-repeat: no-repeat;
}

section#top #top-banner #top-banner-background-4 div {
  background: url('http://www.hotelnclapaz.com/restapi/public/archivos/temp/home-mitru-05.jpg');
  -ms-background-size: cover;
  background-size: cover;
  background-position: 50% 85%;
  background-repeat: no-repeat;
}

section#top #top-banner #top-banner-center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: auto;
  background-color: none;
  z-index: 5;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  filter: alpha(opacity=0);
  text-align: center;
}

section#top #top-banner #top-banner-center h1 {
  font: 500 24px/20px $open-sans, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  font-weight: 600;
  margin-top: 45px;
  -webkit-transform: scaleY(0.94);
  transform: scaleY(0.94);
}

section#top #top-banner #top-banner-center h1 #top-h1 {
  font: 500 40px/26px $open-sans, sans-serif;
}

@media only screen and (min-width: 737px) and (max-width: 920px) {
  section#top #top-banner #top-banner-center h1 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 736px) {
  section#top #top-banner #top-banner-center h1 {
    font: bold 24px/28px $open-sans, sans-serif;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.4;
    font-weight: bold;
    letter-spacing: 1px;
    margin-top: 30px;
  }
  section#top #top-banner #top-banner-center h1 #top-h1 {
    font: 500 26px/26px $open-sans, sans-serif;
  }
}

section#top #top-banner #top-banner-center a {
  display: inline-block;
  overflow: hidden;
  margin-top: 20px;
  padding: 0.6rem 1.6rem;
  width: 180px;
  line-height: 40px;
  color: white;
  background: $yellow;
  font: 500 18px/36px $open-sans, sans-serif;
  border-radius: 6px;
  z-index: 2;
  opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scaleY(0.94);
  transform: scaleY(0.94);
  transition: all 0.2s ease-in;
  -webkit-backface-visibility: 'hidden';
  backface-visibility: 'hidden';
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
  transition: 0.3s ease all;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

section#top #top-banner #top-banner-center a::selection {
  background: transparent;
}

section#top #top-banner #top-banner-center a::-moz-selection {
  background: transparent;
}

section#top #top-banner #top-banner-center a:hover {
  color: #fff;
}

section#top #top-banner #top-banner-center a:hover:after {
  top: 0;
}

section#top #top-banner #top-banner-center a span {
  position: absolute;
  left: 37px;
  z-index: 2;
}

section#top #top-banner #top-banner-center a img {
  position: absolute;
  right: 34px;
  top: 16px;
  z-index: 2;
  transition: all 0.1s ease-in;
}

section#top #top-banner-footer {
  position: absolute;
  width: 100%;
  bottom: 40px;
  text-align: center;
  color: #fff;
  font: 500 16px/16px $open-sans, sans-serif;
  z-index: 5;
  -webkit-transform: scaleY(0.94);
  transform: scaleY(0.94);
  opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  filter: alpha(opacity=0);
}

@media only screen and (min-width: 737px) and (max-width: 920px) {
  section#top #top-banner-footer {
    bottom: 20px;
  }
}
