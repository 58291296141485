/* ========================
Contact
======================== */

.contact__wrapper {
  min-height: 100vh;
  height: auto;
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  &:before {
    position: absolute;
    content:'';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    z-index: 1;
  }
  h4{
    z-index:2
  }

}


.flex-grid {
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
}

iframe.map {
  min-width: 50vw;
  min-height: 42vh;
  height: auto;
  margin: 2em;
  padding: 0;
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 768px) {
  .flex-grid {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
