/* ========================
Loader
======================== */

// Loader ================================
$loadingTime: 3s;
$color: #ec6426;

@keyframes loading {
  0% {
    width: 0;
  }

  50% {
    width: 45%;
  }

  70% {
    width: 55%;
  }

  100% {
    width: 100%;
  }
}

@keyframes imageIn {
  0% {
    transform: scale(0.65);
  }

  100% {
    transform: scale(0.75);
  }
}

@keyframes loaderOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.45;
  }

  70% {
    width: 0.65;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.loader__box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 999999999999;
  background: url('../../assets/loader-bg.jpg');
  background-position: center;
  background-size: cover;
  transition: 0.3s ease all;

  img {
    max-width: 250px;
    display: block;
    margin: 2em auto;
    animation: imageIn 3s ease-in-out forwards;
  }
}

.bg {
  background-color: #ff7600;
  animation: loading $loadingTime linear forwards;
  border-radius: 65px;
  position: relative;
  z-index: 999;
}

.el {
  margin-top: 2em;
  // color: $color;
  width: 15vw;
  border-radius: 65px;
  border: none;

  &:after {
    padding-left: 20px;
    content: '';
    display: block;
    text-align: center;
    padding: 0.15em 0.675em;
    border-radius: 65px;
    // color: $color;
    background: white;
    mix-blend-mode: color-burn;
  }
}

@media screen and (max-width: 1200px) {
  .el {
    width: 20vw;
  }
}

@media screen and (max-width: 650px) {
  .loader__box {
    img {
      max-width: 220px;
      margin: 2em auto;
    }
  }

  .el {
    width: 50vw;
  }
}
