/* ========================
PopUp
======================== */
.btn-primary{
  display: block !important;
  color: white !important;
  text-transform: uppercase !important;
  &:hover{
    color: #212529 !important;
    background-color: #e7940b !important;
    border-color: #db8c0a !important;
  }
}

.cmodal {
  background: rgba($color: #ffffff, $alpha: 0.8);
  box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.3);
  position: absolute;
  bottom: 0;
  left: 3em;
  max-width: 320px;
  z-index: 35;

  &__close {
    position: absolute;
    border: none;
    background: white;
    border-radius: 100%;
    right: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.65em;
    padding: 0.75em;
    outline: none !important;
    cursor: pointer;
  }

  &__header--image {
    padding-bottom: 1em;

    img {
      min-width: 100%;
      max-width: 320px;
    }

    svg {
      color: #727272;
      font-size: 2em;
    }
  }

  &__body {
    padding: 1em;

    h4 {
      color: #4a4a4a;
      font-family: 'Lato', sans-serif;
      font-weight: bold;
      margin-bottom: 1em;
    }

    p {
      color: #4a4a4a;
      font-family: 'Lato', sans-serif;
      line-height: 1.2;
      margin-bottom: 2em;
    }

    button {
      display: block;
      margin: 0 auto;
      width: 90%;
      color: white;
      text-transform: uppercase;
      cursor: pointer !important;
    }
  }

  &--hide {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .cmodal {
    background: rgba($color: #ffffff, $alpha: 1);
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.3);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 320px;
    z-index: 999999;
    margin: 1em auto;
  }
}

// @media screen and (min-width: 1280px) {
//   .cmodal {
//     display: flex;
//     background: rgba(255, 255, 255, 0.8);

//     &__body {
//       background: white;
//     }
//   }
// }
