/* ========================
Services
======================== */

.logo__restaurant {
  position: absolute;
  max-width: 200px;
  display: block;
  height: 100px;
  top: calc(50% - 50px);
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 30;
}

.serviceName {
  position: absolute;
  text-align: center;
  display: block;
  width: 100%;
  z-index: 20;
  margin-top: 6em;
  color: white;
  letter-spacing: 1px;
  font-family: 'Lato', sans-serif;
  font-size: 2.125em;
  text-transform: uppercase;
}
