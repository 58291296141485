/* ========================
Card Box
======================== */

.cards__box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 1rem;
  min-width: 40vw;
  margin: 1rem;
  // padding: 1rem;
  // overflow-y: scroll;

  .card {
    background: transparent;
    text-align: center;
    border: none;
    cursor: pointer;

    img {
      padding: 1em;
      max-width: 250px;
      max-height: 175px;
      display: block;
      margin: 0 auto;
    }

    p {
      line-height: 1.2;
      margin-top: 0.5rem;
      max-width: 250px;
      display: block;
      margin: 0.5rem auto 0;
    }
  }
}

h5.back {
  z-index: 999;
}

a p {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

@media screen and (min-width: 769px) {
  h5.back {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .cards__box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-gap: 1rem;
    min-width: 40vw;
    padding: 0;
    margin: 0;

    .card {
      background: transparent;
      text-align: center;
      border: none;
      cursor: pointer;

      img {
        padding: 0.25em;
        display: block;
        margin: 0 auto;
      }

      p {
        margin-top: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  h5.back {
    margin-top: 2em;
  }
}
