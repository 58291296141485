/* ========================
Navbar
======================== */

@import '../../scss/hamburger.scss';

// Topbar
.topbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.005)
  );

  position: absolute;
  width: 100%;
  padding: 0.85rem 0 0;
  z-index: 99999;

  &--logo {
    max-width: 300px;
    transform: translateY(-50px);
   // padding: 0.75rem;
    flex: 1 1 60%;
  }

  &--left {
    flex: 1 1 20%;
    display: flex;
    align-items: center;
    padding: 0 0.45rem;

    .btn {
      margin-top: -0.55em;
      background: transparent;
      border: none;

      img {
        width: 1.125em;
      }
    }
  }

  &--right {
    flex: 1 1 20%;
    display: flex;
    justify-content: flex-end;
    padding: 0 0.45rem;

    span.icons {
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      color: #333;
      border-radius: 65px;
      margin: 0.45rem;
      width: 0.25rem;
      height: 0.25rem;
      padding: 0.75rem;
      cursor: pointer;
    }
  }
}

// Menu
.overlay__menu {
  background: rgba(0, 0, 0, 0.65);
  color: white;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  display: flex;
  align-items: center;

  opacity: 0;
  visibility: hidden;
  padding: 4rem 5rem;
  transition: all 0.4s ease;

  .menu__items ul {
    list-style: none;
    text-align: right;
    font-size: 1.65rem;
    border-right: 3px solid gray;
    padding: 1rem;

    li {
      font-family: 'Nunito', sans-serif;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      margin: 1.65rem 0;
      color: white;
      text-transform: uppercase;
      transition: 0.3s ease all;
      user-select: none;
      cursor: pointer;

      &:hover {
        color: #bebebe;
        text-decoration: none;
      }
    }

    li a {
      font-family: 'Nunito', sans-serif;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      color: white;
      text-transform: uppercase;
      transition: 0.3s ease all;
      cursor: pointer;

      &:hover {
        color: #bebebe;
        text-decoration: none;
      }
    }
  }
}

.is-active {
  visibility: visible;
  opacity: 1;
}

.btn.lang {
  border: none;
  outline: none;
}

.is-spanish {
  border: 1px solid #dadada;
  border-radius: 100%;
  padding: 0.2em;
}

.is-english {
  border: 1px solid #dadada;
  border-radius: 100%;
  padding: 0.2em;
}

// Menu hover content ==================
.menu__content {
  display: none;
  // padding: 1em;
  margin: 1em;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 65vh;
  width: auto;
}

.hover--active {
  display: block;
}

@media screen and (min-width: 768px) {
  span.icon-right {
    display: none;
  }
}

span.icon-right {
  margin-left: 0.5em;
}

@media screen and (min-width: 768px) {
  .btn.lang__responsive {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .overlay__menu {
    background: rgba(0, 0, 0, 0.75);
    .menu__items{
      ul{
        padding-top: 70px;
      }
    }
  }

  .menu__content {
    padding: 0;
    overflow-x: hidden;
    max-height: 70vh;
  }

  .topbar--logo {
    max-width: 250px;
    padding: 0.65rem;
    transform: translateY(-55px) !important;
    margin-left: -50px;
    margin-right: -50px;
  }

  .overlay__menu {
    justify-content: center;
    padding: 2rem;
  }

  .btn.lang {
    display: none;
  }

  .topbar--right span.icons {
    margin: 0.35rem;
    width: 0.5rem;
    height: 0.5rem;
    padding: 0.75rem;
  }

  .menu__items ul {
    border-right: none !important;
    text-align: center;
    font-size: 0.875rem;

    li {
      text-align: center;
      font-size: 0.875em;

      a {
        font-size: 0.875em;
      }
    }
  }
}
