/* ========================
PopUp
======================== */

.PopUp {
  background: rgba($color: #ffffff, $alpha: 0.8);
  box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.3);
  position: absolute;
  bottom: 0;
  right: 2em;
  min-width: 300px;
  max-width: 400px;
  z-index: 999;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: white;
    padding: 1em;

    h4 {
      font-family: 'Lato', sans-serif;
      font-size: 1.2em;
      color: #f5a623;
      text-transform: uppercase;
    }

    button {
      cursor: pointer;
      border: none;
      background: white;

      svg {
        color: #727272;
      }
    }
  }

  &__body {
    // background: white;
    padding: 1em;

    p {
      color: #4a4a4a;
      font-family: 'Lato', sans-serif;
      line-height: 1.2;
      margin-bottom: 1em;
    }

    a {
      color: #000;
    }

    ul {
      margin: 1.2em;
      list-style: initial;

      li {
        font-family: 'Lato', sans-serif;
      }
    }

    button {
      display: block;
      margin: 0 auto;
      width: 90%;
      color: white;
      text-transform: uppercase;
      cursor: pointer !important;
    }
  }
}

.no-border {
  outline: none !important;
  border: none;
}

// Animation Up
.is-down {
  animation: hide 1s forwards;
}
@keyframes hide {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(100% - 59px));
  }
}

// Animation Down
.is-up {
  animation: show 1s forwards;
}
@keyframes show {
  0% {
    transform: translateY(calc(100% - 59px));
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 680px) {
  .PopUp {
    //z-index: 99999;
  }
}

@media screen and (max-width: 650px) {
  .PopUp {
    //background: rgba($color: #ffffff, $alpha: 1);
    background: rgba(255,255,255,0.85);
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.3);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: inherit;
    margin: 0 auto;
    overflow-y: scroll;
  }
}
