/* ========================
Reserve
======================== */

.Reserve {
  //background: white;
  background: rgba(255,255,255,.95);
  box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.3);
  position: absolute;
  bottom: 0;
  right: 1em;
  max-width: 500px;
  z-index: 99999;
  opacity: 0;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: white;
    padding: 1em;

    h4 {
      font-family: 'Lato', sans-serif;
      font-size: 1.2em;
      color: #f5a623;
      text-transform: uppercase;
    }

    button {
      cursor: pointer;
      border: none;
      background: white;

      svg {
        color: #727272;
      }
    }
  }

  &.contact{
    width: 500px;
  }
  &__body {
    // background: white;
    padding: 0.65em;

    p {
      color: #4a4a4a;
      font-family: 'Lato', sans-serif;
      line-height: 1.2;
      //margin-bottom: 2em;
      margin-bottom: 15px;
      padding: 0px 14px;
      a{
       // color: #ec6426;
        text-decoration: underline;
      }
    }
    .info{
      //color: #ec6426;

    font-weight: bold;
    }
    a {
      color: #000;
    }

    button {
      display: block;
      margin: 0 auto;
      width: 90%;
      color: white;
      text-transform: uppercase;
      cursor: pointer !important;
    }
  }
}

// Animation
.modal-reserve-up {
  animation: isUp 1s forwards;
}
@keyframes isUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal-reserve-down {
  animation: isDown 2s forwards;
}
@keyframes isDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@media screen and (max-width: 680px) {
  .Reserve {
    //z-index: 99999;
    &.contact{
      width: 500px;
    }
  }
}

@media screen and (max-width: 650px) {
  .Reserve {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    margin: 0 auto;
    &.contact{
      width: 100%;
    }
    &__body {
      padding: 1em;

      p {
        color: #4a4a4a;
        font-family: 'Lato', sans-serif;
        line-height: 1.2;
        margin-bottom: 15px;
      }

      button {
        display: block;
        margin: 0 auto;
        width: 90%;
        color: white;
        text-transform: uppercase;
        cursor: pointer !important;
      }
    }
  }

  .grid__form > * {
    width: 91.5% !important;
  }
}

.grid__form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  justify-content: center;
  justify-items: center;
  grid-gap: 0.35em;
  min-width: 200px;

  &--header {
    align-items: center;
    //padding: 0.25em;
    padding: 0 15px;
    @media screen and (max-width: 768px) {
        padding:0
      }
    p {
      //color: #ec6426;
      font-weight:bold;
      margin-bottom: 0;
      width: calc(100% - 40px);
      @media screen and (max-width: 768px) {
        text-align: left;
        margin-top: 0; 
      }
    
    }
  }
}

.grid__form--calendar {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 0.45em;
  //padding: 0.45em;
  padding: 0 14px 0;

  label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 12px;
    color: #9B9B9B;
    letter-spacing: 0;
    input{
      font-size: 16px;
    }
  }
}

form p {
  font-size: 0.875em;
  padding: 0 0.25em;
  color: #777;
}

// Custom Inputs
@mixin inputs {
  background: white;
  border: 1px solid #b6b6b6;
  color: #777777;
  outline: none !important;
  font-family: 'Lato', sans-serif;
  border-radius: 6px;
  box-shadow: none;
  transition: 0.3s ease all;

}

select {
  width: 100%;
  margin: 0.65em;
  padding: 5px;
  @include inputs;
}

input[type='text'],
input[type='email'],
input[type='tel'],
textarea {
  @include inputs;
  padding: 0.45em 0.75em;
  margin: 0.45em;
  width: auto;

  &:focus {
    border: 1px solid orange;
  }
  position: relative;
  &::placeholder {
    color: #d0d0d0;
  }
}

textarea {
  display: block;
  margin: 7px auto;
  min-height: 100px;
  width: 88.5%;
}

input[type='number'],
input[type='date'] {
  @include inputs;
  margin-top: 0.45em;
  padding: 0.25em;
  width: auto;

  &:focus {
    border: 1px solid orange;
  }

  &::placeholder {
    color: #d0d0d0;
  }
}

.label-large {
  grid-column: span 2;
}

@media screen and (max-width: 768px) {
  .grid__form {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
  .grid__form--calendar {
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }
}

@media screen and (max-width: 650px) {
  .grid__form--header {
    text-align: center;
  }

  .grid__form {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 0.5em;
  }

  .grid__form--calendar {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-gap: 0.5em;
    padding: 0.25em;
  }

  form {
    padding-bottom: 4em;
  }
}

@media screen and (max-width: 450px) {
  form p {
    margin-top: 1em;
  }
}

@media screen and (max-width: 330px) {
  .grid__form--calendar {
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    grid-gap: 0.5em;
    padding: 0.25em;
  }
}


.grid__form{
  grid-gap:0;
  .custom-input{
    padding: 5px;
    margin: 5px;
    width: calc(100% - 40px);
    font-size: 16px !important;
    line-height: 0;
  }
}