/* ========================
General
======================== */
@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=Lato|Open+Sans:400,600|Nunito:700');

@import './custom';
@import '~bootstrap/scss/bootstrap';

// Import individual pages styles
@import './pages/home';
@import './pages/rooms';
@import './pages/services';
@import './pages/blog';
@import './pages/contact';

$open-sans: 'Open Sans';
$lato: 'Lato';

// General Styling
*,
[class*=' icon-'],
[class^='icon-'] {
  -webkit-font-smoothing: antialiased;
}

*,
:after,
:before {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #fafafa;
  color: #545457;

  max-width: 100vw;
  overflow-x: hidden;
}


::selection {
  background: rgba(97, 97, 97, 0.445);
}
::-moz-selection {
  background: rgba(97, 97, 97, 0.445);
}

h1,
h2,
h3,
h4,
p {
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

h1,
h2,
h3,
h4,
p,
ul {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
ul li {
  margin: 0;
  padding: 0;
}
a,
img {
  outline: none;
}
p {
  font-family: 'Lato', sans-serif;
}

label {
  color: rgba($color: #000000, $alpha: 0.875);
}


@media only screen and (min-device-width: 480px)
and (max-device-width: 992px)
and (orientation: landscape) {
  body{
    &:before{
      content:  '';
      display:block ;
      position: fixed;
      width: 300%;
      height: 300%;
      top: -100%;
      left: -100%;
      right: 0;
      bottom: 0;
      z-index: 9999999;
      transform: rotate(90deg);
      background: url('../assets/rotate3.gif') center no-repeat, rgba(0,0,0,.9);
    }
  }
}

// Buttons
.button--large {
  padding: 0.7rem 5rem;
  text-transform: uppercase;
  color: white;
  font-family: 'Lato', sans-serif;

  background: $yellow;
  border: 0;
  cursor: pointer !important;

  &:hover {
    color: $gray-100;
    cursor: pointer !important;
  }
}

.button--medium {
  padding: 0.7rem 3rem;
  text-transform: uppercase;
  color: white;
  font-family: 'Lato', sans-serif;

  background: $yellow;
  border: 0;
  cursor: pointer !important;

  &:hover {
    color: $gray-100;
    cursor: pointer !important;
  }
}

// Overwrite styles from slider
.react-slideshow-container + div.indicators {
  align-items: flex-end;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: transparent;
  z-index: 20;
}

.react-slideshow-container + div.indicators > div {
  width: 3px !important;
  height: 2em !important;
  margin-right: 10px !important;
  border-radius: 50px !important;
  background: white !important;
  transition: all 0.3s ease;
  &:nth-child(1) {
           margin-left: 10px !important;
 }
}

.react-slideshow-container + div.indicators > div.active {
  background: #ec6426 !important;
  height: 3.2em !important;
}

@media screen and (max-width: 600px) {
    .indicators {
        transform: scale(.7);
        bottom: 45px !important;
    }
   .react-slideshow-container + div.indicators > div {
      margin-right: 12px !important;
      &:nth-child(1) {
           margin-left: 12px !important;
      }
    }
}
.each-fade .image-container img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.react-slideshow-container .nav {
  display: none;
}

section.body {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.menu__content::-webkit-scrollbar,
.menu__content::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.menu__content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba($color: #757575, $alpha: 0.65);
}

.menu__content::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 10px;
  background-color: transparent;
}
