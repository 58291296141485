/* ========================
Rooms
======================== */

.roomName {
  position: absolute;
  text-align: center;
  display: block;
  width: 100%;
  z-index: 20;
  margin-top: 6em;
  color: white;
  letter-spacing: 1px;
  font-family: 'Lato', sans-serif;
  font-size: 2.125em;
  text-transform: uppercase;
}
.body{
  &:before{
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .3);
    z-index: 2;
  }
}